import React from "react";
import { Layout } from "../components/themed";

const NosotrosPage = () => {
  return (
    <Layout>
      <h1 className="text-3xl font-bold">Nosotros</h1>
    </Layout>
  );
};

export default NosotrosPage;
